// Extend Date types with new methods or properties
// Ref: https://www.typescriptlang.org/docs/handbook/release-notes/typescript-1-8.html#augmenting-globalmodule-scope-from-modules
import { Timestamp } from 'firelordjs';

import { getDateISOStr, getTimeStr } from '@/lib/helpers/dates';

// Extend global Date type with new functions
declare global {
  interface Date {
    /**
     * Returns the time string representation of the Date object.
     * @function
     * @memberof Date.prototype
     * @returns {string} The time string representation (HH:MM).
     * @example
     * const now = new Date();
     * const timeString = now.getTimeStr();
     * // Returns a string representing the time in HH:MM format
     */
    getTimeStr(): string;
    /**
     * Returns the date ISO string representation of the Date object.
     * @function
     * @memberof Date.prototype
     * @returns {string} The date string representation (YYYY-MM-DD).
     * @example
     * const now = new Date();
     * const dateString = now.getDateISOStr();
     * // Returns a string representing the time in YYYY-MM-DD format
     */
    getDateISOStr(): string;
  }
}
Date.prototype.getTimeStr = getTimeStr;
Date.prototype.getDateISOStr = getDateISOStr;

// Extend Timestamp type from from "firelordjs"
declare module 'firelordjs' {
  // Augment the 'Timestamp' class definition with interface merging
  interface Timestamp {
    /**
     * Returns the time string representation of the Date object.
     * @function
     * @memberof Timestamp.prototype
     * @returns {string} The time string representation (HH:MM).
     * @example
     * const timestamp = Timestamp.fromDate(new Date());
     * const timeString = timestamp.getTimeStr();
     * // Returns a string representing the time in HH:MM format
     */
    getTimeStr(): string;
    /**
     * Returns the date ISO string representation of the Date object.
     * @function
     * @memberof Date.prototype
     * @returns {string} The date string representation (YYYY-MM-DD).
     * @example
     * const now = new Date();
     * const dateString = now.getDateISOStr();
     * // Returns a string representing the time in YYYY-MM-DD format
     */
    getDateISOStr(): string;
  }
}
Timestamp.prototype.getTimeStr = function () {
  return this.toDate().getTimeStr();
};
Timestamp.prototype.getDateISOStr = function () {
  return this.toDate().getDateISOStr();
};
