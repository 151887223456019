'use client';

/**
 * Client Component to use Context Provider in root layout.
 * Reference:
 * - https://nextjs.org/docs/app/building-your-application/rendering/composition-patterns#using-context-providers
 * - https://vercel.com/guides/react-context-state-management-nextjs#rendering-third-party-context-providers-in-server-components
 */
import React, { Suspense } from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { AuthContextProvider } from '@/lib/context/AuthContext';
import { EditSchedulerContextProvider } from '@/lib/context/EditSchedulerContext';
import { SchedulerContextProvider } from '@/lib/context/SchedulerContext';
import { URLParamsContextProvider } from '@/lib/context/URLParamsContext';

import { nodeEnv } from '@/lib/env';
// Import files to execute global scripts at startup
import '@/lib/helpers/addToDevTools';
import '@/lib/helpers/dates.extends';

export function Providers({ children }: { children: React.ReactNode }) {
  // Do not use Suspense on local development to avoid "Hydration failed because the initial UI does not match what was rendered on the server" error.
  // TODO: See https://sentry.io/answers/hydration-error-nextjs/
  const SuspenseOrFragment =
    nodeEnv === 'production' ? Suspense : React.Fragment;
  return (
    // Why use Suspense: https://github.com/vercel/next.js/discussions/61654
    <SuspenseOrFragment>
      <URLParamsContextProvider>
        <AuthContextProvider>
          <SchedulerContextProvider>
            <EditSchedulerContextProvider>
              <DndProvider backend={HTML5Backend}>{children}</DndProvider>
            </EditSchedulerContextProvider>
          </SchedulerContextProvider>
        </AuthContextProvider>
      </URLParamsContextProvider>
    </SuspenseOrFragment>
  );
}
