// Add utility function to window object so they are available to test in devtools console.
import {
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from 'firelordjs';

import { get, merge, set } from 'lodash';

import { firestore } from '@/lib/firebase';
import * as auth from '@/lib/firebase/auth';
import * as collections from '@/lib/firebase/db/collections';
import * as helpers from '@/lib/firebase/db/helpers';
import * as queries from '@/lib/firebase/db/queries';

import * as env from '@/lib/env';
import * as dates from '@/lib/helpers/dates';
import * as features from '@/lib/helpers/features';
import * as functions from '@/lib/helpers/functions';
import * as scheduler from '@/lib/helpers/scheduler';

export function addToDevTools(
  namespacePath: string,
  object: any,
  { addOnlyIfInDev = false, getGlobalFn = functions.getGlobal } = {}
) {
  // Make key available on window.ISD.namespacePath so DevTools in the browser
  // can run commands like: "ISD.some.path.someValueFunction()"
  // ISD is short for Iron Sheepdog and it is recommended for namespacePath
  // to match the module path.

  const windowObj: any = getGlobalFn();

  if (addOnlyIfInDev === true && env.nodeEnv !== 'development') return;

  const rootNamespace = 'ISD';
  const path = [rootNamespace, ...namespacePath.split('.')];
  const existingObject = get(windowObj, path) || {};
  const mergedObject = merge(existingObject, object);
  set(windowObj, path, mergedObject);

  // Add lodash if it doesn't already exist
  if (!windowObj._) {
    set(windowObj, '_', require('lodash'));
  }
}

addToDevTools('firelordjs', {
  getDocs,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
  startAfter,
});

addToDevTools('lib.firebase', { auth, firestore });
addToDevTools('lib.firebase.db', { collections, queries, helpers });

addToDevTools('lib.helpers', { env, dates, features, functions, scheduler });
